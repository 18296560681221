import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { decodeEntities } from "../utils/helpers";
import { AcfComponent } from "./componentLoader";

const Blog = (props) => {
  const { data, pageContext, location } = props;
  const {
    wordpressPost: page = [],
    allWordpressPost,
    categoryFilter,
    wordpressCategory,
    wordpressWpSettings,
    siteSettings,
  } = data;
  const { title, yoast, acf } = page
    ? page
    : { title: null, yoast: {}, acf: {} };
  const { showAuthor, customCss } = siteSettings.options;
  const { wordpressUrl } = wordpressWpSettings;
  const { title: siteTitle, date_format } = wordpressWpSettings;
  const { edges: posts } = allWordpressPost;
  const featuredImage = wordpressCategory?.featured_media
    ? wordpressCategory.featured_media
    : "";
  const { layout } = acf || { layout: null };
  return (
    <>
      <SEO
        title={`${
          yoast.metaTitle
            ? yoast.metaTitle
            : `Blog | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
        yoast={yoast}
        location={location}
      />
      {layout &&
        layout.map((item, index) => {
          if (!item.__typename) return null;
          const layoutComponentName = item.__typename.replace(
            "WordPressAcf_",
            ""
          );
          return (
            <AcfComponent
              key={index}
              index={index}
              componentName={layoutComponentName}
              item={item}
              location={location}
              pageContext={pageContext}
              posts={posts}
              date_format={date_format}
            />
          );
        })}
    </>
  );
};

export default Blog;

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
        customCss
      }
    }
    wordpressPost: wordpressPage(slug: { eq: "news" }) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      acf {
        layout: layout_page {
          __typename
          ...FlexibleBlockQuery
          ...HeaderQuery
          ...BlogFeedQuery
          ...StaffProfilesQuery
          ...FullWidthImageContainedQuery
          ...FullWidthImageQuery
          ...ImageAndContentBlockQuery
          ...ContentAndIconListQuery
          ...TwoCardBlockQuery
          ...TwoColumnQuery
          ...PostListQuery
        }
      }
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          path
          id
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    wordpressCategory(slug: { eq: "uncategorised" }) {
      featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
